<template>
  <div id="maps-container" style="height: 100%; width: 100%;">
    <div id="google-map" style="height: 100%;" />
  </div>
</template>

<script>
import { getLinearDeviceArmLinePath, getCropPoints } from '@/libs/helpers'
import { Loader } from '@googlemaps/js-api-loader'
import constants from '../constants'
const { EQUIPMENT_TYPE, MAPS_SETTINGS, NAME_TIMERS, STATES } = constants

export default {
  name: 'DevicesMap',
  props: {
    configurationDevicesGroups: Array,
    timePerDevice: Number,
    maxTimePerGroup: Number,
    nameTimer: Number
  },
  data () {
    return {
      map: null,
      mapElements: [],
      Map: {},
      currentMapIndex: 0,
      devicesGroups: [],
      devicesInterval: null,
      mapBounds: []
    }
  },
  async mounted () {
    this.devicesGroups = this.configurationDevicesGroups
    await this.loadMapsLibrary()
    this.$mapElements = []
    this.$infoWindows = []
    await this.initMap()
    this.startDrawingDevices()
  },
  methods: {
    async loadMapsLibrary () {
      // Check if library is already loaded
      if (window.google && window.google.maps) {
        return
      }

      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly'
      })
      await loader.load()
      const { Map } = await google.maps.importLibrary('maps')
      this.Map = Map
      await google.maps.importLibrary('geometry')
    },
    async initMap () {
      // Create map
      this.map = new this.Map(document.getElementById('google-map'), {
        maxZoom: 15,
        center: new google.maps.LatLng(0, 0),
        mapTypeId: 'satellite',
        panControl: false ,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false,
        draggable: false,
        keyboardShortcuts: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        }
      })
    },
    async drawDevices (devices) {
      let currentMapElements = []
      let currentMapBounds = new google.maps.LatLngBounds()
      devices.forEach((device, deviceIndex) => {
        let drawObj
        if (device.type == EQUIPMENT_TYPE.PIVOT) {
          drawObj = this.drawPivot(device, deviceIndex)
        } else if (device.type == EQUIPMENT_TYPE.LINEAR) {
          drawObj = this.drawLinear(device, deviceIndex)
        } else if (device.type == EQUIPMENT_TYPE.IR) {
          drawObj = this.drawIrrigationReel(device, deviceIndex)
        }
        currentMapElements = currentMapElements.concat(drawObj.newElements)
        currentMapBounds = currentMapBounds.union(drawObj.newBounds)
      })

      // Add shapes to current map elements
      this.$mapElements = currentMapElements

      // Put devices into map view
      this.mapBounds = currentMapBounds
      // this.map.panToBounds(currentMapBounds)
      this.map.fitBounds(currentMapBounds)
    },
    removeDevicesFromMap () {
      this.$mapElements.forEach(element => {
        element.setMap(null)
      })
      this.$infoWindows.forEach(mapGroup => {
        mapGroup.forEach(infoWindow => {
          infoWindow.close()
          infoWindow = null
        })
      })
      this.$mapElements = []
    },
    startDrawingDevices () {
      // Initialize info windows array
      this.devicesGroups.forEach(async (devices, index) => {
        this.$infoWindows[index] = []
      })

      // Draw first devices group
      const currentDevicesGroup = this.devicesGroups[this.currentMapIndex]
      this.drawDevices(currentDevicesGroup, this.currentMapIndex)
      this.openInfoWindows()

      const interval = currentDevicesGroup.length * this.timePerDevice > this.maxTimePerGroup ? this.maxTimePerGroup : currentDevicesGroup.length * this.timePerDevice
      if (this.devicesInterval) clearInterval(this.devicesInterval)
      this.devicesInterval = setInterval(async () => {
        this.removeDevicesFromMap()
        this.currentMapIndex = (this.currentMapIndex + 1) % this.devicesGroups.length
        this.drawDevices(this.devicesGroups[this.currentMapIndex], this.currentMapIndex)
        this.openInfoWindows()

      }, interval * 1000)
    },
    drawPivot (device, deviceIndex) {
      let currentMapElements = []
      try {
        // Draw pivot crop circle
        const circle = new google.maps.Circle({
          strokeColor: device.color,
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: device.color,
          fillOpacity: 0.3,
          center: device.center,
          radius: device.radius,
          map: this.map
        })
        circle.setOptions({zIndex: MAPS_SETTINGS.Z_INDEX.CROP})
        currentMapElements.push(circle)

        // Set info window default params
        const infoWindowParams = {
          content: device.name,
          position: device.center
        }

        if (device.angle) {
          // Draw pivot device position
          const positionCoordinates = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(device.center), device.radius, device.angle)
          const devicePositionPath = [
            device.center,
            positionCoordinates
          ]
          const devicePositionLine = new google.maps.Polyline({
            path: devicePositionPath,
            strokeColor: 'white',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: this.map
          })
          devicePositionLine.setOptions({zIndex: MAPS_SETTINGS.Z_INDEX.DEVICE})
          currentMapElements.push(devicePositionLine)

          infoWindowParams.position = positionCoordinates
        }


        // If the "Hide name" setting is not "Always" update the Info Window position
        if (this.nameTimer !== NAME_TIMERS.ALWAYS.VALUE) {
          this.$infoWindows[this.currentMapIndex][deviceIndex] = new google.maps.InfoWindow(infoWindowParams)
        }

        return {
          newElements: currentMapElements,
          newBounds: circle.getBounds()
        }
      } catch (error) {
        console.log(`Error drawing device ${device.name}`, error.message)
      }
    },
    drawLinear (device, deviceIndex) {
      let currentMapElements = []

      try {
        // Draw linear crop
        const cropLine = new google.maps.Polygon({
          paths: getCropPoints(device.subtype, device.currentLot, device.location, device.length),
          strokeColor: device.color,
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: device.color,
          fillOpacity: 0.3,
          map: this.map
        })
        currentMapElements.push(cropLine)

        // Set info window default params
        const infoWindowParams = {
          content: device.name,
          position: device.currentLot.startPoint
        }

        if (device.location.lat !== 0 && device.location.lng !== 0) {
          // Draw linear device position
          const devicePositionPath = getLinearDeviceArmLinePath(device.subtype, device.currentLot, device.location, device.length)
          const devicePositionLine = new google.maps.Polyline({
            path: devicePositionPath,
            strokeColor: 'white',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: this.map
          })
          currentMapElements.push(devicePositionLine)

          // Set info window position to device location
          infoWindowParams.position = device.location
        }

        // Add device to map bounds to display it on the map
        const bounds = new google.maps.LatLngBounds()
        cropLine.getPath().forEach(coordinate => {
          bounds.extend(coordinate)
        })

        // If the "Hide name" setting is not "Always" update the Info Window position
        if (this.nameTimer !== NAME_TIMERS.ALWAYS.VALUE) {
          this.$infoWindows[this.currentMapIndex][deviceIndex] = new google.maps.InfoWindow(infoWindowParams)
        }

        // Add shapes to current map elements
        return {
          newElements: currentMapElements,
          newBounds: bounds
        }
      } catch (error) {
        console.log(`Error drawing device ${device.name}`, error.message)
      }
    },
    drawIrrigationReel (device, deviceIndex) {
      let currentMapElements = []
      try {
        // Draw linear crop
        const cropLine = new google.maps.Polygon({
          paths: device.currentLot,
          strokeColor: device.color,
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: device.color,
          fillOpacity: 0.3,
          map: this.map
        })
        currentMapElements.push(cropLine)

        // Set info window default params
        const northernmostCoord = device.currentLot.reduce((max, coord) =>
          coord.lat > max.lat ? coord : max,
          device.currentLot[0]
        )
        const infoWindowParams = {
          content: device.name,
          position: northernmostCoord
        }

        let circlePosition
        if (device.location.lat !== 0 && device.location.lng !== 0) {
          if ([STATES.DISCONNECTED, STATES.OFF, STATES.MOVING_EQUIPMENT].includes(device.state)) {
            circlePosition = device.location
          } else if (device.central.lat !== 0 && device.central.lng !== 0) {
            // Draw linear device position
            const devicePositionPath = [device.location, device.central]
            const devicePositionLine = new google.maps.Polyline({
              path: devicePositionPath,
              strokeColor: 'white',
              strokeOpacity: 1,
              strokeWeight: 4,
              map: this.map
            })
            currentMapElements.push(devicePositionLine)

            circlePosition = device.central
          }

          // Circle of current position
          const circle = new google.maps.Circle({
            strokeColor: 'white',
            strokeOpacity: 1,
            strokeWeight: 4,
            fillColor: 'white',
            fillOpacity: 1,
            center: circlePosition,
            radius: 30,
            map: this.map
          })
          circle.setOptions({zIndex: MAPS_SETTINGS.Z_INDEX.CROP})
          currentMapElements.push(circle)
        }

        // Add device to map bounds to display it on the map
        const bounds = new google.maps.LatLngBounds()
        cropLine.getPath().forEach(coordinate => {
          bounds.extend(coordinate)
        })

        // Add infoWindow to show device name
        const infoWindow = new google.maps.InfoWindow(infoWindowParams)
        infoWindow.open({
          map: this.map
        })
        this.$infoWindows[this.currentMapIndex][deviceIndex] = infoWindow

        return {
          newElements: currentMapElements,
          newBounds: bounds
        }
      } catch (error) {
        console.log(`Error drawing device ${device.name}`, error.message)
      }
    },
    clearDevicesInterval () {
      clearInterval(this.devicesInterval)
    },
    openInfoWindows () {
      switch (this.nameTimer) {
        case NAME_TIMERS.NEVER.VALUE:
          this.$infoWindows[this.currentMapIndex].forEach(infoWindow => {
            infoWindow.open({map: this.map})
          })
          break
        case NAME_TIMERS.AFTER_10_SECONDS.VALUE:
          this.$infoWindows[this.currentMapIndex].forEach(infoWindow => {
            infoWindow.open({map: this.map})

            // If the screen time of the group is greater than 10 seconds or it's only one group, then set the timeout
            if ((this.devicesGroups[this.currentMapIndex].length * this.timePerDevice > NAME_TIMERS.AFTER_10_SECONDS.TIME && this.maxTimePerGroup > NAME_TIMERS.AFTER_10_SECONDS.TIME) || this.devicesGroups.length === 1) {
              setTimeout(() => { infoWindow.close() }, NAME_TIMERS.AFTER_10_SECONDS.TIME * 1000) // Set timeout to 10 seconds
            }
          })
          break
        case NAME_TIMERS.AFTER_30_SECONDS.VALUE:
          this.$infoWindows[this.currentMapIndex].forEach(infoWindow => {
            infoWindow.open({map: this.map})

            // If the screen time of the group is greater than 30 seconds or it's only one group, then set the timeout
            if ((this.devicesGroups[this.currentMapIndex].length * this.timePerDevice > NAME_TIMERS.AFTER_30_SECONDS.TIME && this.maxTimePerGroup > NAME_TIMERS.AFTER_30_SECONDS.TIME) || this.devicesGroups.length === 1) {
              setTimeout(() => { infoWindow.close() }, NAME_TIMERS.AFTER_30_SECONDS.TIME * 1000) // Set timeout to 30 seconds
            }
          })
          break
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.devicesInterval)
    this.removeDevicesFromMap()
  },
  watch: {
    configurationDevicesGroups (value) {
      this.devicesGroups = value
    }
  }
}
</script>

<style>
.gm-style-iw button {display: none !important;}
</style>
